<template>
  <div class="wrongList">
    <div class="listIcon">
      <van-image
        width="20px"
        height="20px"
        :src="require('@/assets/images/list.png')"
      />
      <h2>错题列表</h2>
    </div>

    <ul class="list">
      <h2 class="caseTitle"></h2>

      <li v-for="(item, index) in caseList">
        <div class="openAndClose">
          <van-image
            width="30px"
            height="30px"
            :src="
              item.isOpen
                ? require('@/assets/images/xiangyou.png')
                : require('@/assets/images/openAndClose.png')
            "
            @click="openAndClose(index)"
          />
        </div>

        <!-- 文字 -->
        <div v-if="item.question.way == 1" class="questionInfo">
          <span class="qid">{{ index + 1 }}、</span>
          <span
            class="type"
            :style="{
              fontWeight: '500',
              color:
                item.question.type == 1
                  ? '#378F85'
                  : item.question.type == 2
                  ? '#C17728'
                  : '#419A29',
            }"
          >
            {{
              item.question.type == 1
                ? "选择题"
                : item.question.type == 2
                ? "判断题"
                : "主观题"
            }}
          </span>

          <div
            :class="item.isOpen ? 'openQuestion' : ''"
            v-html="item.question.question"
          ></div>
        </div>

        <!-- 图片 -->
        <div
          v-if="item.question.way == 2"
          class="questionInfo"
          :class="item.mask ? 'mask' : ''"
        >
          <span class="qid">{{ index + 1 }}、</span>
          <span
            class="type"
            :style="{
              fontWeight: '500',
              color:
                item.question.type == 1
                  ? '#378F85'
                  : item.question.type == 2
                  ? '#C17728'
                  : '#419A29',
            }"
          >
            {{
              item.question.type == 1
                ? "选择题"
                : item.question.type == 2
                ? "判断题"
                : "主观题"
            }}</span
          >
          <div style="padding-top: 20px">
            <van-image
              width="100%"
              height="100%"
              :src="prefix(item.question.question)"
            />
          </div>
        </div>

        <!-- 附件 -->
        <div v-if="item.question.way == 3" class="questionInfo">
          <span class="qid">{{ index + 1 }}、</span>
          <span
            class="type"
            :style="{
              fontWeight: '500',
              color:
                item.question.type == 1
                  ? '#378F85'
                  : item.question.type == 2
                  ? '#C17728'
                  : '#419A29',
            }"
          >
            {{
              item.question.type == 1
                ? "选择题"
                : item.question.type == 2
                ? "判断题"
                : "主观题"
            }}
          </span>
          <div style="margin-top: 10px">
            <iframe
              width="100%"
              :height="item.isOpen ? '100px' : '500px'"
              :src="
                item.question.file_ext == 'pdf'
                  ? prefix(item.question.question)
                  : prefixFile(prefix(item.question.question))
              "
              style="display: block"
              frameborder="0"
            >
            </iframe>
          </div>
        </div>

        <!-- 选项 -->
        <!-- <ul class="option" v-show="!item.isOpen">
          <li v-for="item in JSON.parse(item.question.question_option)">
            <p v-html="item.text"></p>
          </li>
        </ul> -->

        <!-- 文字答案 my -->
        <h4
          v-if="item.question.type == 1 || item.question.type == 2"
          :class="item.studentAnswer.answer.length > 20 ? '' : 'myAnswer'"
        >
          <p>我的答案：</p>
          <p>{{ item.studentAnswer.answer }}</p>
          <p>（2022-10-11 12：00）</p>
        </h4>

        <!-- 图片答案 my -->
        <h4 :class="item.mask ? 'mask' : ''" v-if="item.question.type == 3">
          <p>我的答案：</p>
          <div>
            <van-image
              width="100%"
              height="100%"
              :src="prefix(item.studentAnswer.answer)"
            />
          </div>
        </h4>

        <!-- 文字答案 case -->
        <h4
          :class="item.question.answer.length > 20 ? '' : 'caseAnswer'"
          v-if="item.question.answer_type == 1"
        >
          <p>题目答案：</p>
          <p :style="{ color: item.question.answer ? '' : 'red' }">
            {{
              item.question.answer ? item.question.answer : "老师没有上传答案"
            }}
          </p>
        </h4>

        <!-- 图片答案 case -->
        <h4
          v-if="item.question.answer_type == 2"
          :class="item.mask ? 'mask' : ''"
        >
          <p>题目答案：</p>
          <div>
            <p
              :style="{ color: item.question.answer ? '' : 'red' }"
              v-if="!item.question.answer"
            >
              老师没有上传答案
            </p>
            <van-image
              v-if="item.question.answer"
              width="100%"
              height="100%"
              :src="prefix(item.question.answer)"
            />
          </div>
        </h4>

        <div class="parsing" v-show="!item.isOpen">
          <h4>题目解析：</h4>
          <div class="parCen">
            <van-empty
              v-if="!item.question.analysis"
              description="老师未上传答案解析"
            />
            <div v-if="item.question.analysis">
              <p>
                <van-image
                  width="100%"
                  height="100%"
                  :src="prefix(item.question.analysis)"
                />
              </p>
              <h4 v-if="item.question.type == 1 || item.question.type == 2">
                故选：{{ item.question.answer }}
              </h4>
            </div>
          </div>
        </div>
      </li>

      <van-empty
        v-if="!caseList.length"
        :image="require('@/assets/images/kong.png')"
        image-size="80"
        description="太棒了！当前没有错题"
      />
    </ul>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { prefix, prefixFile } from "@/common/js/utils.js";

defineProps(["caseList"]);
let emit = defineEmits(["changeOpen", "changeMask"]);

const openAndClose = (index) => {
  emit("changeOpen", index);
  emit("changeMask", index);
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";
.listIcon {
  display: flex;
  margin-bottom: 10px;
  h2 {
    margin: 0;
    font-size: 10px;
    margin-left: 10px;
  }
}
.wrongList {
  padding: 10px;
  .list {
    .bs();
    background-color: #fff;
    padding: 20px 0 20px 20px;
    border-radius: 8px;
    .caseTitle {
      margin: 0;
    }

    .openQuestion {
      overflow: hidden;
      text-overflow: ellipsis;
      /* 弹性伸缩盒子模型显示 */
      display: -webkit-box;
      /* 限制在一个块元素显示的文本的行数 */
      -webkit-line-clamp: 1;
      /* 设置或检索伸缩盒对象的子元素的排列方式 */
      -webkit-box-orient: vertical;
    }

    li {
      background-color: #fff;
      position: relative;
      padding-right: 25px;
      // height: 20px;
      // line-height: 20px;
      margin-top: 15px;
      font-size: 12px;

      .qid {
        color: rgb(77, 161, 219);
      }

      .type {
        padding: 0px 10px;
        box-shadow: 0 0 0 0.5px rgb(77, 161, 219);
        border-radius: 10px;
      }

      .option {
        color: #676767;
      }

      .myAnswer,
      .caseAnswer {
        display: flex;
        align-items: center;
        p:nth-child(2) {
          color: red;
        }
        p:nth-child(3) {
          color: rgb(139, 131, 131);
        }
      }
      .caseAnswer {
        p:nth-child(2) {
          color: green;
        }
      }

      .parsing {
        .parCen {
          padding: 10px;
          box-shadow: 0 0 0 0.5px #777;
          border-radius: 5px;
        }
      }

      .mask {
        height: 100px;
        overflow: hidden;
      }
    }

    .openAndClose {
      position: absolute;
      top: -5px;
      right: 5px;

      transition: 0.3s;
    }
  }
}
</style>
