<template>
  <div class="about">
    <s-header :hasBack="true" :title="'错题本'" />
    <n-bar :userType="'student'" :activeItemName="'index'" />
    <support :otherClass="'width40'" />

    <div class="pageContent">
      <div class="modular-base">
        <div class="modular-item">
          <div class="nav">
            <ul class="subject">
              <li><h2 class="title">学科</h2></li>
              <li>
                <div
                  class="all"
                  :class="subIndex == -1 ? 'active' : ''"
                  @click="clickAllSubject"
                >
                  全部
                </div>
              </li>
              <li v-for="(item, index) in subjectList" :key="item.id">
                <div
                  class="subjectNameBox"
                  :class="index == subIndex ? 'active' : ''"
                  :style="{ boxShadow: `0 0 0 0.5px ${item.color}` }"
                  @click="subjectSearch(item.id, index)"
                >
                  {{ item.subjectName }}({{ item.total }})
                </div>
              </li>
            </ul>
            <ul class="statute">
              <li><h2 class="title">题型</h2></li>
              <li>
                <div
                  class="all"
                  :class="staIndex == -1 ? 'staActive' : ''"
                  @click="clickAllSta"
                >
                  全部
                </div>
              </li>
              <li v-for="(item, index) in caseType" :key="item.id">
                <div
                  class="statuteBox"
                  :style="{
                    boxShadow: `0 0 0 0.5px ${item.color}`,
                    color: item.color,
                  }"
                  :class="index == staIndex ? 'staActive' : ''"
                  @click="typeSearch(item.id, index)"
                >
                  {{ item.name }}({{ item.total }})
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="modular-content">
        <div class="modular-item">
          <van-list
            v-model:loading="caseLoading"
            :finished="caseLoadingFinished"
            :finished-text="caseList.length ? '没有更多了' : ''"
            @load="getCaseList"
          >
            <WrList
              :caseList="caseList"
              @changeOpen="changeOpen"
              @changeMask="changeMask"
            ></WrList>
          </van-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref, onMounted } from "vue";
import { secondsToIS } from "@/common/js/utils";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import WrList from "@/components/student/WrongList.vue";
import {
  apiGetWrongSubjectLists,
  apiGetWrongTypeLists,
  apiGetWrongBookList,
} from "@/service/student.js";
// import { apiGetBooksList, apiGetBookTree } from "@/service/book";
import { apiGetCasesList } from "@/service/student";

let userInfo = JSON.parse(localStorage.getItem("userInfo"));
onBeforeMount(async () => {
  await getSubject();
  await getCaseType();
});

let subjectList = ref([]);
const getSubject = async () => {
  let res = await apiGetWrongSubjectLists();
  if (res) {
    subjectList.value = res.data;
  }
};

let caseType = ref([]);
const getCaseType = async () => {
  let res = await apiGetWrongTypeLists();
  if (res) {
    caseType.value = res.data;
  }
};

let caseList = ref([]);
let caseLoading = ref(false);
let caseLoadingFinished = ref(false);
let casePage = ref(0);
let caseLimit = ref(10);
let subjectId = ref(0);
let typeId = ref(0);

const getCaseList = async () => {
  caseLoading.value = true;
  caseLoadingFinished.value = false;
  casePage.value++;

  const params = {
    page: casePage.value,
    limit: caseLimit.value,
    subjectId: subjectId.value,
    typeId: typeId.value,
    // appointedDate: '2022-07-19',
  };

  const apiRes = await apiGetWrongBookList(params);
  if (apiRes) {
    for (let i = 0; i < apiRes.data.length; i++) {
      let item = apiRes.data[i];
      const totalTimeObj = secondsToIS(item.totalTime);
      item.totalTimeStr = totalTimeObj.chinese;
      item.isOpen = true;
      item.mask = true;
      caseList.value.push(item);
    }
    caseLoading.value = false;
    if (apiRes.data.length < caseLimit.value) {
      caseLoadingFinished.value = true;
    }
  } else {
    caseLoading.value = false;
    caseLoadingFinished.value = true;
  }
};

const changeOpen = (index) => {
  caseList.value[index].isOpen = !caseList.value[index].isOpen;
};
const changeMask = (index) => {
  caseList.value[index].mask = !caseList.value[index].mask;
};

const clickAllSubject = async () => {
  subIndex.value = -1;
  casePage.value = 0;
  caseList.value = [];
  subjectId.value = 0;
  await getCaseList();
};

// 点击学科筛选
let subIndex = ref(-1);
const subjectSearch = async (id, index) => {
  subIndex.value = index;
  subjectId.value = id;
  casePage.value = 0;
  caseList.value = [];
  await getCaseList();
};

const clickAllSta = async () => {
  staIndex.value = -1;
  casePage.value = 0;
  typeId.value = 0;
  caseList.value = [];
  await getCaseList();
};

// 点击状态筛选
let staIndex = ref(-1);
const typeSearch = async (id, index) => {
  staIndex.value = index;
  casePage.value = 0;
  typeId.value = id;
  caseList.value = [];
  await getCaseList();
};

// _mc.style.marginLeft =
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";

.nav {
  display: flex;
  font-size: 12px;

  .subject {
    margin-right: 20px;
  }
  .subject,
  .statute {
    text-align: center;
    .title {
      margin: 0;
      font-size: 10px;
      color: rgb(197, 171, 171);
    }
    li {
      margin-bottom: 10px;
    }

    .active {
      color: #fff;
      background-color: rgb(102, 204, 255);
      box-shadow: none;
      font-weight: 500;
    }

    .staActive {
      color: #fff !important;
      background-color: rgb(102, 204, 255);
      box-shadow: none;
      font-weight: 500;
    }
  }
  .all {
    box-shadow: 0 0 0 0.5px red;
    padding: 5px;
    border-radius: 5px;
  }

  .subjectNameBox {
    box-shadow: 0 0 0 0.5px red;
    padding: 5px;
    border-radius: 5px;
  }

  .statuteBox {
    padding: 5px;
    border-radius: 5px;
  }
}

/* 竖屏 */
@media screen and (orientation: portrait) {
  .modular-base {
    // width: 180px;
    height: auto;
    margin: 10px;
    position: fixed;
    top: 30px;
    left: 0;
    bottom: 40px;
    overflow-y: auto;

    .modular-item {
      padding: 4px;
      margin-bottom: 5px;
    }
  }

  .modular-content {
    margin-left: 150px;
    padding-left: 10px;
    height: auto;
    min-height: calc(100vh - 30px - 40px);
    // background-color: @fc;
  }
}

/* 横屏 */
@media screen and (orientation: landscape) {
  .modular-base {
    // width: 170px;
    height: auto;
    margin: 6px 10px 10px 10px;
    position: fixed;
    top: 30px;
    left: 50px;
    bottom: 20px;
    overflow-y: auto;

    .modular-item {
      padding: 4px;
      margin-bottom: 5px;
    }
  }

  .modular-content {
    margin-left: 165px;
    height: auto;
    min-height: calc(100vh - 30px - 20px);
    // background-color: @fc;
  }
}
</style>
